<template>
    <HeroPage v-if="pageData" :data="pageData" />

    <VideoGridFilterCollection :paging="fetchDataParams.paging"
        :collectionFilter="['Categories', 'Level', 'Coaches & Trainers']" :searchParams="searchParams" />
</template>

<script>
import {
    fetchPageBySlug,
} from "@streampac.io/chef_sp_1";

import HeroPage from "@/components/HeroPage";

//import FindYourTeacherSection from "@/components/FindYourTeacherSection";
import VideoGridFilterCollection from "@/components/VideoGridFilterCollection";

export default {
    name: "VideoOverview",
    inject: ["isAuthenticated"],
    components: {
        HeroPage,
        VideoGridFilterCollection,
    },
    // metaInfo() {
    //     return {
    //         title: "Yoga Classes - Pick the yoga style you like",
    //     };
    // },
    data() {
        return {
            pageData: null,
            fetchDataParams: {
                paging: {
                    pageNumber: 1,
                    pageSize: 6,
                },
            },
            searchParams: [
                {
                    type: "Video Type",
                    //TEST
                    // value: "0e8f0731-8a0c-4685-be3d-a5f3ccb5dd99",

                    //LIVE
                    value: "c80d1369-3861-4e0c-8278-3c29605b2c03"
                },
            ],
        };
    },
    created() {
        this.fetchPage();
    },
    methods: {
        fetchPage() {
            fetchPageBySlug(this.isAuthenticated, "all-videos").then(
                (response) => {
                    this.pageData = response;
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
